

 export const baseUrl="https://innerweb.org"
 export const socketUrl = "https://innerweb.org/ws"

// export const socketUrl = "http://innerweb.org/emailserver"
// export const baseUrl="http://44.203.60.45:4000"
// export const socketUrl = "https://44.203.60.45:4001"
//export const socketUrl = "http://44.203.60.45:4001"
// export const baseUrl="http://localhost:4000"
//  export const socketUrl = "http://localhost:4001"



export  const emailcolumns = [
    {
      name: "Email Addresses",
      selector: (row) => row.emailAddress,
      sortable: true,
    },
    // {
    //   name: "Edit",
    //   selector: (row) => (<svg class="w-6 h-6 text-green-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    //   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"/>
    // </svg>
    // ),
    //   sortable: false,
    // },
    
  ];

  
